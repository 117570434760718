<template>
  <!-- <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini" mini-variant-width="100"> -->
  <v-navigation-drawer
    app
    permanent
    v-model="drawer"
    :mini-variant.sync="mini"
    expand-on-hover
  >
    <v-list-item>
      <v-icon color="white" @click="mini = !mini">mdi-menu</v-icon>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped nav>
      <template v-for="(item, index) in links">
        <v-list-group
          color="white"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          v-if="
            $store.state.users.user &&
            item.permission.includes($store.state.users.user.role)
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title color="white" class="small-font">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:prependIcon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
          >
            <v-list-item-icon>
              <v-icon color="white">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="small-font">{{
              child.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider
          v-if="item.sectionEnd"
          :key="index + 100"
          class="my-1"
        ></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      drawer: true,
      mini: true,
      links: [
        {
          title: "General",
          icon: "mdi-home-outline",
          permission: ["workshop manager","director", "admin", "technical manager", "visitor"],
          items: [
            { title: "Home", to: { name: "Home" }, icon: "mdi-home" },
            // { title: "Under Development", to: { name: "UnderDev" }, icon: "mdi-screwdriver" },
          ],
          sectionEnd: true,
        },
        // {
        //   title: "Pricing Engine",
        //   icon: "mdi-tag-outline",
        //   permission: ["user", "admin"],
        //   items: [
        //     {
        //       title: "Calculator",
        //       to: { name: "Calculator" },
        //       icon: "mdi-calculator",
        //     },
        //   ],
        //   sectionEnd: true,
        // },

        // {
        //   title: "Marketing",
        //   icon: "mdi-message-text-outline",
        //   sectionEnd: true,
        //   permission: ["admin"],
        //   items: [],
        // },
        // {
        //   title: "Clients",
        //   icon: "mdi-email-outline",
        //   sectionEnd: true,
        //   permission: ["admin"],
        //   items: [],
        // },
        {
          title: "Finance",
          icon: "mdi-finance",
          sectionEnd: true,
          permission: ["admin","director", "workshop manager", "technical manager"],
          items: [
            {
              title: "Report Generation",
              to: "/dashboard/report-generation",
              icon: "mdi-file-chart",
            }
          ],
        },
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          sectionEnd: true,
          permission: ["admin", "director"],
          items: [
            {
              title: "Manage Users",
              to: "/dashboard/users/manageusers/changeusersettings",
              icon: "mdi-account-multiple-plus",
            },
          ],
        },
      ],
      filteredLinks: [],
    };
  },
  created() {
    this.fetchFilteredLinks();
  },
  watch: {
    // Watch user changes to ensure user data is ready
    "$store.state.users.user": {
      handler(newUser) {
        if (newUser && newUser.id && newUser.role) {
          this.fetchFilteredLinks();
        }
      },
      immediate: true, // Trigger immediately on initialization
      deep: true, // Observe nested properties
    },
  },
  methods: {
  async fetchFilteredLinks() {
    try {
      const user = this.$store.state.users.user;

      // Ensure both userId and userRole are available
      if (!user || !user.id || !user.role) {
        console.warn("User data is incomplete (ID or Role missing), waiting...");
        return;
      }

      const userId = user.id;
      const userRole = user.role;

      // Fetch sections from the API using Axios
      const response = await axios.get(`/api/userJobSection/get/${userId}`);
      const sections = response.data.data.sections;

      console.log("API Sections:", sections); // Debugging line

      if (!Array.isArray(sections) || sections.length === 0) {
        console.warn("No sections retrieved or sections is not an array.");
        return;
      }

      console.log("Links before filtering:", this.links); // Debugging line

      // Filter links based on sections and role
      // Manually filter links based on sections and role
      this.filteredLinks = [];
      for (const link of this.links) {
        if (sections.includes(link.title)) {
          link.permission.push(userRole);
        }
      }

    } catch (error) {
      console.error(
        "Error fetching sections:",
        error.response ? error.response.data : error.message
      );
    }
  },
},


};
</script>

<style scoped>
* {
  color: white;
}
.group-heading {
  /* background-color: #dfedff; */
  /* margin-bottom: 5px; */
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-heading-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.group-heading-text {
  font-weight: 700 !important;
}
.v-icon.v-icon {
  color: black;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .small-font *,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .v-list-group__header__append-icon i {
  color: white;
}
::v-deep .v-list-group__header__prepend-icon {
  margin-right: 12px !important;
}

</style>
