<template>
  <!-- Data table for contct list: start -->

  <v-row
    class="d-flex flex-row justify-space-between align-start ma-0 pa-0"
    align="stretch"
  >
    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular
            :size="25"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <span class="pa-2 text-center" style="line-height: 2em"
            >Processing...</span
          >
        </div>
        <div class="py-2 d-flex align-baseline justify-center"></div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar
      v-model="showSnackbar"
      timeout="2000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="3"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">
              {{ key }}: {{ value }}.
            </div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <!-- Edit Dialog Box: start -->
    <v-dialog v-model="dialog" max-width="500px" width="500px" persistent>
      <v-card class="pa-5 rounded-2">
        <div class="profile-div">
          <h5 class="text-center">{{ formTitle }}</h5>

          <v-text-field
            v-model="editedItem.firstName"
            required
            label="First Name"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.lastName"
            label="Last Name"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.email"
            label="Email"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.assigned_number"
            label="Mobile Number"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-select
            v-model="editedItem.role"
            :items="roles"
            label="Select Role"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-select>
          <!-- Searchable Multiselect Dropdown for Project Manager -->
          <!-- <v-autocomplete
            v-if="editedItem.role === 'technical manager'"
            v-model="selectedJobs"
            :items="jobs"
            item-value="value"
            item-text="text"
            label="Select Jobs"
            dense
            solo
            hide-details
            multiple
            return-object
            :search-input.sync="jobSearch"
            class="rounded-2 my-3"
            @change="handleJobSelection"
          /> -->
          <v-autocomplete
            v-if="editedItem.role === 'visitor'"
            v-model="selectedSections"
            :items="sections"

            label="Select Sections"
            dense
            solo
            hide-details
            multiple
            class="rounded-2 my-3"
          />
          <v-text-field
            v-if="editedIndex == -1"
            v-model="password"
            label="Password"
            type="password"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-text-field
            v-if="editedIndex == -1"
            v-model="repeatPassword"
            label="Repeat Password"
            type="password"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>

          <!-- <div class="red--text small-font">{{ dialogStatus }}</div> -->

          <!-- <v-btn color="grey" outlined text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-4" text outlined :disabled="requestSent">
              {{ formButtonTitle }}
            </v-btn> -->
          <div class="d-flex justify-end py-0 my-0 mx-0 px-0 dialogs-div">
            <v-btn
              color="white"
              class="mx-1 my-1"
              small
              elevation="2"
              @click="close"
            >
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="mx-0 my-1"
              small
              elevation="2"
              :disabled="requestSent"
              @click="save()"
            >
              <v-icon class="mr-1" small>mdi-content-save</v-icon>
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Edit Dialog Box: end -->

    <!-- Password Box: start -->
    <v-dialog v-model="passwordDialog" max-width="500px" flat persistent>
      <v-card class="pa-5 rounded-2">
        <div class="profile-div">
          <h5 class="text-center">Change Password</h5>

          <v-text-field
            v-model="passwords.password"
            label="New Password"
            type="password"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>
          <v-text-field
            v-model="passwords.repeatPassword"
            label="Repeat Password"
            type="password"
            dense
            solo
            hide-details
            class="rounded-2 my-3"
          ></v-text-field>

          <!-- <v-card-actions class="px-8 pb-8 small-font">
              <div>{{ passwordDialogStatus }}</div>
              <v-spacer></v-spacer>
              <v-btn color="grey" outlined text> Cancel </v-btn>
              <v-btn color="blue darken-4" text outlined> Update </v-btn>
            </v-card-actions> -->

          <div class="d-flex justify-end py-0 my-0 mx-0 px-0 dialogs-div">
            <v-btn
              color="white"
              class="mx-1 my-1"
              small
              elevation="2"
              @click="closeChangePassword"
            >
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="mx-0 my-1"
              small
              elevation="2"
              :disabled="requestSent"
              @click="confirmChangePassword()"
            >
              <v-icon class="mr-1" small>mdi-content-save</v-icon>
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Password Dialog Box: end -->

    <!-- Delete Dialog Box: start -->
    <v-dialog v-model="dialogDelete" max-width="500px" width="500px" persistent>
      <v-card class="pa-5 rounded-2">
        <div class="profile-div">
          <h4 class="text-center">
            Are you sure you want to delete this user?
          </h4>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined text  style="letter-spacing: normal">Cancel</v-btn>
            <v-btn color="red" outlined text  :disabled="requestSent" style="letter-spacing: normal">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions> -->

          <div class="d-flex justify-center py-0 mt-5 mx-0 px-0 dialogs-div">
            <v-btn
              color="white"
              class="mx-1 my-1"
              small
              elevation="2"
              @click="closeDelete"
            >
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="mx-0 my-1"
              small
              elevation="2"
              :disabled="requestSent"
              @click="deleteItemConfirm()"
            >
              <v-icon class="mr-1" small>mdi-trash-can</v-icon>
              Delete
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-sheet
        color="white"
        elevation="3"
        rounded
        class="d-flex align-start justify-center"
        style="min-height: 90vh"
      >
        <!-- users datatable -->

        <v-data-table
          :headers="headers"
          :items="users"
          sort-by="name"
          class="px-3 py-4 fill-height w-100"
          dense
          :footer-props="{ 'items-per-page-options': [20, 30, 50, 100, 250] }"
        >
          <template v-slot:top>
            <!-- History row -->
            <v-row class="px-4 py-1 align-baseline justify-center" no-gutters>
              <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
                <h4
                  class="my-2 mx-0 px-0 text-left pr-2 w-100 d-flex flex-row align-baseline justify-space-between"
                >
                  <span>All Users</span>
                  <v-btn
                    color="white"
                    style="text-transform: capitalize"
                    @click="dialog = true"
                    small
                    elevation="2"
                  >
                    <v-icon class="mr-1" small
                      >mdi-account-multiple-plus-outline</v-icon
                    >
                    Add User
                  </v-btn>
                </h4>
              </v-col>
            </v-row>
          </template>

          <!-- User Admin Verified slot for text manipulation -->
          <!-- <template v-slot:[`item.adminVerified`]="{ item }">
              <span>{{ item.adminVerified ? "Verified" : "Unverified" }}</span>
            </template> -->

          <!-- User Email Verified slot for text manipulation -->
          <!-- <template v-slot:[`item.verified`]="{ item }">
              <span>{{ item.verified ? "Verified" : "Unverified" }}</span>
            </template> -->

          <!-- User role slot for text capitalization -->
          <template v-slot:[`item.role`]="{ item }">
            <span class="text-capitalize">{{ item.role }}</span>
          </template>

          <!-- Actions slot -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="bd1"
              class="mr-2"
              small
              @click="changePassword(item)"
            >
              mdi-account-key
            </v-icon>
            <v-icon color="bd3" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <!-- Empty table slot -->
          <template v-slot:no-data>
            <div>No Users found...</div>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["users", "usersRequest"],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "First Name",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      { text: "Last Name", value: "lastName" },
      { text: "Role", value: "role" },
      { text: "Email", value: "email" },
      // { text: "Email Verified", value: "verified" },
      // { text: "Admin Verified", value: "adminVerified" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      email: "",
      assigned_number: "",
      role: "",
      adminVerified: false,
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      email: "",
      assigned_number: "",
      role: "",
      adminVerified: false,
    },
    password: "",
    repeatPassword: "",
    roles: ["admin", "director","workshop manager", "technical manager", "visitor"],
    jobs: [],
    selectedJobs: [],
    jobSearch: "",
    sections: ["General", "Finance", "Users"], // Dropdown options for department
    selectedSections: [], // Selected sections for department
    adminVerifiedOptions: ["verified", "unverified"],

    requestSent: false,

    // table loading
    errorStatus: "No contacts available, reload?",

    // alert
    dialogStatus: "",

    // user passwords change
    passwordDialog: false,
    passwordValid: false,
    passwordDialogStatus: "",
    passwords: {
      id: 0,
      password: "",
      repeatPassword: "",
    },

    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex == -1 ? "Add User" : "Edit User";
    },
    formButtonTitle() {
      return this.editedIndex == -1 ? "Add User" : "Update User";
    },

    loading() {
      return this.requestSent;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async fetchJobs() {
      try {
        const response = await this.axios.get("/api/jobs/list");
        this.jobs = response.data.map((job) => ({
          value: job.Number,
          text: `${job.Number} - ${job.Name}`,
        }));
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    },
    async fetchUserJobs(userId) {
      try {
        const response = await this.axios.get(
          `/api/userJobSection/get/${userId}`
        );
        // console.log("Edit Item:", response.data.data.jobs);
        const userJobValues = response.data.data.jobs.map((job) => job);

        // Mark jobs as selected if they exist in the response
        this.selectedJobs = this.jobs.filter((job) =>
          userJobValues.includes(job.value)
        );
      } catch (error) {
        console.error("Error fetching user jobs:", error);
      }
    },
    async fetchUserSection(userId) {
      try {
        const response = await this.axios.get(
          `/api/userJobSection/get/${userId}`
        );
        // console.log("Edit Item:", response.data.data.jobs);
        const userSectionValues = response.data.data.sections.map((sec) => sec);

        // Mark jobs as selected if they exist in the response
        this.selectedSections = this.sections.filter((sec) =>
          userSectionValues.includes(sec)
        );
      } catch (error) {
        console.error("Error fetching user jobs:", error);
      }
    },
    handleJobSelection() {
      // console.log("Selected jobs:", this.selectedJobs);
    },
    // snackbar and overlay
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const editJob = { ...item };
      this.editedItem.adminVerified = this.editedItem.adminVerified
        ? "verified"
        : "unverified";
      this.dialog = true;

      // if (editJob.role === "technical manager") {
      //   // Fetch all jobs and user-specific jobs
      //   this.fetchJobs();
      //   this.fetchUserJobs(editJob.id);
      // } else {
      //   this.selectedJobs = [];
      // }
      if (editJob.role === "visitor") {
        // Fetch all jobs and user-specific jobs
        this.fetchUserSection(editJob.id);
      } else {
        this.selectedSections = [];
      }
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.requestSent = true;
      this.axios
        .put("/api/users/user/delete", {
          id: this.editedItem.id,
        })
        .then((res) => {
          this.requestSent = false;
          this.snackbarShow(true, "Info", res.data.message);
          this.closeDelete();
          if (!res.data.success) return;
          this.users.splice(this.editedIndex, 1);
        })
        .catch((err) => {
          this.requestSent = false;
          this.closeDelete();
          this.snackbarShow(
            true,
            "Error",
            "User could not be deleted - check your internet connection"
          );
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialogStatus = "";
        this.password = "";
        this.repeatPassword = "";
        this.selectedJobs = [];
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.editedItem.firstName || !this.editedItem.lastName) {
        return this.snackbarShow(true, "Error", "Name fields can not be empty");
      }

      if (!this.editedItem.email) {
        return this.snackbarShow(true, "Error", "Email can not be empty");
      }
      if (!this.editedItem.role) {
        return this.snackbarShow(true, "Error", "Role can not be empty");
      }
      // if (
      //   this.editedItem.role === "technical manager" &&
      //   this.selectedJobs.length === 0
      // ) {
      //   return this.snackbarShow(
      //     true,
      //     "Error",
      //     "Please select at least one job for the technical manager."
      //   );
      // }
      if (
    this.editedItem.role === "visitor" &&
    this.selectedSections.length === 0
  ) {
    return this.snackbarShow(
      true,
      "Error",
      "Please select at least one section for the visitor."
    );
  }
      this.requestSent = true;
      let user = Object.assign({}, this.editedItem);
      user.adminVerified = true;
      user.role = user.role.toLowerCase();

      if (this.editedIndex > -1) {
        const previousRole = this.users[this.editedIndex].role;
        // console.log("Previous Role:", user.id);
        if (
          (previousRole === "technical manager" &&
          user.role !== "technical manager") || (previousRole === "visitor" && user.role !== "visitor")
        ) {
          // Delete userJobSection data for this user
          this.axios
            .delete(`/api/userJobSection/delete/${user.id}`)
            .then((res) => {
              console.log("User job section deleted successfully:", res.data);
            })
            .catch((err) => {
              console.error("Failed to delete user job section:", err);
              this.snackbarShow(
                true,
                "Error",
                "Failed to delete user job section. Please try again."
              );
            });
        }
        this.axios
          .put("/api/users/user/edit", user)
          .then((res) => {
            this.requestSent = false;
            this.dialogStatus = res.data.message;
            this.snackbarShow(true, "Info", res.data.message);
            if (!res.data.success) return;
            Object.assign(this.users[this.editedIndex], this.editedItem);
            if(user.role === "admin" || user.role === "workshop manager" || user.role === "director"){
              this.close();
            }
            // Handle job or section updates for project manager role
            // if (user.role === "technical manager") {
            //   const payload = {
            //     userId: user.id,
            //     jobs: this.selectedJobs.map((job) => job.value),
            //   };
            //   this.axios
            //     .post("/api/userJobSection/edit", payload)
            //     .then(() => {
            //       this.snackbarShow(true, "Info", "Jobs updated successfully.");
            //       this.close();
            //     })
            //     .catch((err) => {
            //       console.error("Failed to update jobs:", err);
            //       this.snackbarShow(true, "Error", "Failed to update jobs.");
            //     });
            // }
            if (user.role === "visitor") {
              const payload = {
                userId: user.id,
                sections: this.selectedSections.map((section) => section),
              };
              this.axios
                .post("/api/userJobSection/edit", payload)
                .then(() => {
                  this.snackbarShow(true, "Info", "User updated successfully.");
                  this.close();
                })
                .catch((err) => {
                  console.error("Failed to update jobs:", err);
                  this.snackbarShow(true, "Error", "Failed to update jobs.");
                });
            }
          })
          .catch((err) => {
            this.requestSent = false;
            this.snackbarShow(
              true,
              "Error",
              "User could not be updated - Check your internet connection"
            );
          });
      } else {
        if (
          !this.password ||
          !this.repeatPassword ||
          this.password.length < 8
        ) {
          this.requestSent = false;
          return this.snackbarShow(
            true,
            "Error",
            "Passwords should be minimum 8 characters"
          );
        }

        // creating new user
        if (this.password !== this.repeatPassword) {
          this.requestSent = false;
          return this.snackbarShow(true, "Error", "Passwords do not match");
        }

        user.password = this.password;
        user.repeatPassword = this.repeatPassword;

        this.axios
          .post("/api/users/adminregister", user)
          .then((res) => {
            this.requestSent = false;
            this.snackbarShow(true, "Info", res.data.message);
           
            if (!res.data.success) return;

            const newUser = res.data.newUser;
            this.users.push(res.data.newUser);
            // Handle job or section creation for project manager role
            if(newUser.role === "admin" || newUser.role === "workshop manager" || newUser.role === "director"){
              this.close();
            }
            // if (newUser.role === "technical manager") {
            //   const payload = {
            //     userId: newUser.id,
            //     jobs: this.selectedJobs.map((job) => job.value),
            //   };
            //   this.axios
            //     .post("/api/userJobSection/create", payload)
            //     .then(() => {
            //       this.snackbarShow(true, "Info", "User added successfully.");
            //       this.close();
            //     })
            //     .catch((err) => {
            //       console.error("Failed to add jobs:", err);
            //       this.snackbarShow(true, "Error", "Failed to add jobs.");
            //     });
            // }
            if (newUser.role === "visitor") {
              const payload = {
                userId: newUser.id,
                sections: this.selectedSections.map((section) => section),
              };
              this.axios
                .post("/api/userJobSection/create", payload)
                .then(() => {
                  this.snackbarShow(true, "Info", "User added successfully.");
                  this.close();
                })
                .catch((err) => {
                  console.error("Failed to add jobs:", err);
                  this.snackbarShow(true, "Error", "Failed to add jobs.");
                });
            }
          })
          .catch((err) => {
            console.log(err);
            this.requestSent = false;
            this.snackbarShow(
              true,
              "Error",
              "User could not be added - Check your internet connection"
            );
          });
      }
    },

    changePassword(item) {
      this.passwords.id = item.id;
      this.passwordDialog = true;
    },

    confirmChangePassword() {
      if (
        !this.passwords.password ||
        !this.passwords.repeatPassword ||
        this.passwords.password.length < 8
      ) {
        return this.snackbarShow(
          true,
          "Error",
          "Passwords should be minimum 8 characters"
        );
      }

      // creating new user
      if (this.password !== this.repeatPassword) {
        return this.snackbarShow(true, "Error", "Passwords do not match");
      }

      if (!this.passwords.id) {
        return this.snackbarShow(true, "Error", "No user selected");
      }

      this.requestSent = true;
      this.axios
        .put("/api/users/user/changeuserpassword", this.passwords)
        .then((res) => {
          this.requestSent = false;
          this.snackbarShow(true, "Info", res.data.message);
          if (!res.data.success) return;
          this.closeChangePassword();
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.snackbarShow(
            true,
            "Error",
            "Password could not be updated - kindly check your internet connection"
          );
          this.closeChangePassword();
        });
    },

    closeChangePassword() {
      this.passwordDialog = false;
      this.passwords.id = 0;
      this.passwords.password = "";
      this.passwords.repeatPassword = "";
      this.passwordDialogStatus = "";
    },
  },
  created() {
    this.fetchJobs();
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
.w-100 {
  width: 100%;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

.dialogs-div .v-btn {
  text-transform: initial;
  width: 146px;
  float: right;
}
.dialogs-div .v-btn .v-icon {
  width: 20px;
}
</style>
