<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5 login-heading">Login</h4>
    <v-text-field @keyup.enter="validate()" v-model="email" :rules="emailRules" label="Email" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="password" :rules="passwordRules" label="Password" required type="password" solo dense></v-text-field>

    <v-btn  class="my-4" style="background-color: #a0a0a0; color: white;" @click="validate()" :disabled="!valid || requestSent">
      Login
    </v-btn>
    <div class="red--text small-font">{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    password: "",
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Invalid Password"],
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "Invalid E-mail"],

    valid: true,
    requestSent: false,
    status: "",
  }),

  methods: {
    validate() {
      this.status = "Logging in...";
      let validated = this.$refs.form.validate();

      if (!validated) {
        this.status = "Invalid Email or Password";
        return;
      }

      // sending request to api
      this.requestSent = true;
      this.axios
        .post("/api/users/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.requestSent = false;
          this.status = res.data.message;
          if (res.data.success) {
            localStorage.setItem("token", res.data.token);
            this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            this.$router.push({ name: "Home" });
          }
          else if (!res.data.success && res.data.redirect === "unverifiedUser") {
            this.$router.push({ name: "PendingVerification", params: { email: this.email } });
          }
          else if (!res.data.success && res.data.redirect === "unverifiedAdminUser") {
            this.$router.push({ name: "PendingAdminVerification" });
          }
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "Could not log in - kindly check your internet connection";
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
.login-heading {
  background: linear-gradient(
    to bottom,
    #a0a0a0,
    #02803f, /* Green */
    #00a651,
    #02cc64
    
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
@media (min-width: 300px) and (max-width: 959px) {
  .login-heading{
    font-size: 1.3rem;
  }
}
</style>
